import React from 'react';
import '../styles/editeur.scss';
import EditorConvertToMarkdown from '../components/editorMarkdown.js';


function Editeur() {
    //faire deux divs cote a cote
    //une pour le markdown
    //une pour le html



    return (
        <div>
            <h1>HexEditeur V0.1a</h1>
            <div className="fenetre-principale">
                <EditorConvertToMarkdown />
            </div>
        </div>
    );
}

export default Editeur;